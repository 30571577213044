
@import "node_modules/bootstrap/scss/bootstrap-reboot.scss";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "node_modules/font-awesome/scss/font-awesome.scss";
@import "node_modules/spinkit/scss/spinkit.scss";
@import "node_modules/swiper/dist/css/swiper";
@import "node_modules/@fancyapps/fancybox/dist/jquery.fancybox";

@import "./animate";
@import "./social-icons";
@import "./cart-system";

[v-cloak] {
  display: none;
}

.pswp__caption__center {
  text-align: center;
}

.pswp__instructions {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  display: none;
}

.pswp__item {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.pswp__item:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

// Negative margins e.g. ml-n1 = margin left negative 1
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m) {
      @each $size, $length in $spacers {

        .#{$abbrev}#{$infix}-n#{$size} { #{$prop}: -$length !important; }
        .#{$abbrev}t#{$infix}-n#{$size},
        .#{$abbrev}y#{$infix}-n#{$size} {
          #{$prop}-top: -$length !important;
        }
        .#{$abbrev}r#{$infix}-n#{$size},
        .#{$abbrev}x#{$infix}-n#{$size} {
          #{$prop}-right: -$length !important;
        }
        .#{$abbrev}b#{$infix}-n#{$size},
        .#{$abbrev}y#{$infix}-n#{$size} {
          #{$prop}-bottom: -$length !important;
        }
        .#{$abbrev}l#{$infix}-n#{$size},
        .#{$abbrev}x#{$infix}-n#{$size} {
          #{$prop}-left: -$length !important;
        }
      }
    }
  }
}